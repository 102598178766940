import React, { useState } from "react";
import { graphql } from "gatsby";
import { Container, Row, Col, Card } from "reactstrap";
import Img from "gatsby-image";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import "./galerie.css";
import Layout from "../components/Layout";

const GalleryTemplate = ({ data }) => {
  const [isOpen, setOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const { frontmatter: gallery } = data.markdownRemark;
  const images = gallery.images.map((img) => ({
    img: img.image.childImageSharp.full.src,
    title: img.title,
  }));

  const openLightbox = (index) => {
    setImageIndex(index);
    setOpen(true);
  };

  return (
    <Layout>
      <Container>
        <Row>
          <Col sm={12}>
            <h1>{gallery.title}</h1>
            <p>{gallery.description}</p>
          </Col>
        </Row>
        <Row className="gallery">
          {gallery.images.map((img, i) => (
            <Col sm={3} xs={6} key={i} onClick={() => openLightbox(i)}>
              <Card className="gallery-item">
                <Img
                  fluid={img.image.childImageSharp.small}
                  alt={img.title}
                  className="card-img-top"
                />
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      {isOpen && (
        <Lightbox
          mainSrc={images[imageIndex].img}
          nextSrc={images[(imageIndex + 1) % images.length].img}
          prevSrc={images[(imageIndex + images.length - 1) % images.length].img}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex((imageIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setImageIndex((imageIndex + 1) % images.length)
          }
          imagePadding={75}
          imageTitle={images[imageIndex].title}
        />
      )}
    </Layout>
  );
};

export default GalleryTemplate;

export const textPageQuery = graphql`
  query GalleryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        date
        description
        images {
          image {
            childImageSharp {
              full: fluid(maxWidth: 1800, quality: 40) {
                ...GatsbyImageSharpFluid
              }
              small: fluid(maxWidth: 400, quality: 40) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
        }
      }
    }
  }
`;
